import React from 'react';
import '../assets/components/stars.scss';

const Stars = () => {
	return (
		<>
			<div id='stars'></div>
			<div id='stars2'></div>
			<div id='stars3'></div>
		</>
	);
}

export default Stars;